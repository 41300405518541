.postest {
  position: absolute !important;
  left: 0 !important;
  z-index: 5000 !important;
  background-color: #fff;
}
.ErrorMessage {
  background-color: #ffa155;
  color: #000;
  margin-top: 10px;
}
.WhiteBox {
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  // width: 100%;
  flex: 1 1 auto;
  box-sizing: border-box;
  display: block;
  position: relative;
  flex-direction: row;
  height: 100%;
  box-shadow: 0 0 7px 5px rgba(0, 0, 0, 0.05);
  padding: 10px;
  :hover {
    cursor: pointer;
  }
  .ThumbMedal {
    width: 100%;
    img {
      width: 100%;
    }
  }
  .DownBar {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    align-content: flex-end;
    flex-direction: row;
    margin-top: 10px;
    .Info {
      display: block;
      min-width: 30px;
      height: 30px;
      border-radius: 8px;
      background-color: #e6e6e6;
      line-height: 30px;
      text-align: center;
      color: #0a4148;
      float: right;
      margin-left: 10px;
    }
  }
  .IndividualProgressBarWrap {
    background-color: #e6e6e6;
    margin: 20px 0 10px 0;
    height: 30px;
    border-radius: 15px;
    text-align: center;
    overflow: hidden;
    position: relative;
    .IndividualProgressBarInner {
      background-image: #0a4148;
      display: block;
      width: 0%;
      height: 30px;
    }
    span {
      color: #0a4148;
      line-height: 20px;
      font-size: 0.8em;
      font-weight: 900;
      background: rgba(255, 255, 255, 0.6);
      border-radius: 10px;
      padding: 0 10px;
      position: absolute;
      top: 5px;
      display: inline-block;
      left: 5px;
    }
  }
  .DateMedal {
    font-size: 12px;
    text-align: center;
    margin: 10px 0 0 0;
  }
}

.ColorCard {
  .ThumbMedal {
    display: block;
    width: 100%;
    filter: grayscale(0%);
  }
}
.BwCard {
  .ThumbMedal {
    display: block;
    width: 100%;
    filter: grayscale(100%);
    img {
      filter: blur(4px);
      margin: 0 auto;
      display: block;
    }
  }
}

.spaceBt {
  margin-bottom: 30px;
}

.Grid5 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  width: 50%;
  float: left;
}
@media (min-width: 428px) {
  .Grid5 {
    width: 50%;
    float: left;
  }
}
@media (min-width: 768px) {
  .Grid5 {
    width: 33.33%;
    float: left;
  }
}
@media (min-width: 992px) {
  .Grid5 {
    width: 25%;
    float: left;
  }
}
@media (min-width: 1360px) {
  .Grid5 {
    width: 20%;
    float: left;
  }
}
.WrapModalhide {
  display: none;
}
.WrapModal {
  opacity: 1;
  display: flex !important;
  //pointer-events: none;

  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 20000;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  .Content {
    display: block;
    cursor: default;
    width: 100%;
    max-width: 600px;
    max-height: 100%;
    .TopBar {
      background: #0a4148;
      background-size: cover;
      height: 75px;
      z-index: 10;
      width: 540px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      border-radius: 15px 15px 0 0;
      position: relative;
      max-width: 600px;
      .CloseButtonX {
        background-color: #fff;
        color: #0a4148;
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-weight: 900;
        border-radius: 20px;
        float: right;
        font-size: 1.4em;
        text-align: center;
        margin: 25px 20px 10px 10px;
        cursor: pointer;
      }
    }
    .Information {
      background-color: #fff;
      max-width: 540px;
      padding: 10px 10px 10px 10px;
      // overflow-y: scroll;
      position: relative;
      margin-top: -40px;
      z-index: 5;
      border-radius: 0 0 15px 15px;
      max-height: 90%;
      overflow-x: hidden;
      @media (min-width: 768px) {
        padding: 40px;
      }
      img {
        width: 120px;
        display: inline-block;
      }
      .Title {
        width: 100%;
        text-align: center;
        align-items: center;
        margin: 20px 0;
        font-size: 16px;
        font-weight: bold;
        text-transform: uppercase;
      }
      .ModalUserEmail {
        padding: 5px;
        color: #0a4148;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        align-items: center;
        background-color: #c4c4c4;
        border-radius: 30px;
        width: 340px;
        min-height: 35px;
        margin: 0 auto 10px;
      }
      .ModalLabel {
        font-weight: bold;
      }
      .ItsDoneWrap {
        border: solid 1px #cccccc;
        border-radius: 4px;
        display: table;
        float: left;
        margin: 0 10px 10px 0;
        padding: 4px 10px;
        span {
          color: #662d91;
          margin-right: 4px;
        }
      }
      .Goals {
        //color: $grayDark;
        // margin-bottom: 30px;
      }
      .Image {
        width: 100%;
        max-width: 200px;
        margin: 0 auto;
        img {
          width: 100%;
        }
      }
      .WrapSteps {
        display: block;
        width: 100%;
        border-top: solid 1px #cccccc;
        margin: 20px 0 30px 0;
        padding: 20px;
        .Steps {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          /*max-width: 400px;*/
          margin: 0 auto;
          .StepsBar {
            display: flex;
            height: 100%;
            float: left;
            max-width: 90%;
            justify-content: flex-end;
            svg {
              width: 100%;
            }

            .ColorMe {
              fill: #ffca55;
            }
            .GrayMe {
              fill: #d7d7d7;
            }
          }
          .stepsNum1 {
            display: none;
          }
          .stepsNum2 svg {
            max-width: 60px;
          }
          .stepsNum3 svg {
            max-width: 120px;
          }
          .stepsNum4 svg {
            max-width: 180px;
          }
          .stepsNum5 svg {
            max-width: 240px;
          }
          .stepsNum6 svg {
            max-width: 300px;
          }
          .stepsNum7 svg {
            max-width: 360px;
          }
          .stepsNum8 svg {
            max-width: 420px;
          }
          .stepsNum9 svg {
            max-width: 480px;
          }
          .stepsNum10 svg {
            max-width: 540px;
          }
          .Star {
            width: 10%;
            float: left;
            margin-left: -8px;
            //max-width: 20%;
            svg {
              max-width: 80px;
              width: 100%;
            }
          }
        }
      }
      .OptionsYesOrNo {
        display: flex;
        justify-content: space-around;
        .CloseButtonText {
          background-color: #ffca55;
          color: #0a4148;
          display: table;
          width: 100px;
          text-transform: uppercase;
          font-weight: bold;
          font-size: 1em;
          // line-height: 40px;
          padding: 5px 20px;
          border-radius: 20px;
          margin: 25px 0 0 0;
          text-align: center;
          pointer-events: fill !important;
        }
      }
    }
  }
}
.ColorModal {
  display: block;
  width: 100%;
  /*overflow: scroll;*/
  height: 100%;
}
.BwModal {
  display: block;
  width: 100%;
  max-width: 600px;
  /*overflow: scroll;*/
  height: 100%;
  .Image {
    filter: blur(4px);
    max-width: 100px;
    margin: 0 auto;
    display: block;
    img {
      filter: grayscale(100%);
    }
  }
}
.Dropdown {
  color: #0a4148;
  background-color: #c4c4c4;
  font-weight: bold;
  font-size: 14px;
  display: inline-block;
  position: relative;
  min-width: 240px;
  height: 30px;
  z-index: inherit;
  cursor: pointer;
  border-radius: 30px;
  padding: 5px 15px;
  &.Large {
    min-width: 250px;
  }
  .Active {
    font-size: 14px;
    top: 0;
    left: 0;
    // z-index: 2;
    position: relative;
    height: 30px;
    width: 100%;
    font-weight: bold;
    padding: 5px 15px;
    border-radius: 15px;
    justify-content: space-between;
    display: flex;
    line-height: 18px;
    align-items: center;
    margin-right: 5px;
    color: #23480a;
    background-color: #c4c4c4;
  }
  &.Dark {
    .Active {
      color: #fff;
      background-color: #662d91;
      span {
        color: rgb(163, 15, 15);
      }
    }

    .Option {
      &:focus,
      &:hover {
        color: rgb(190, 43, 43);
        background-color: #93278f;
      }
    }
    .ActiveOption {
      color: #fff;
      background-color: darken(#93278f, 15%);
    }
  }
}
.Option {
  display: absolute;
  padding: 10px 15px;
  width: 100%;
  text-align: left;
  min-width: 100px;
  min-height: 100px;
  background-color: #ffca55;
  &:hover {
    padding: 20px 15px !important;
    background-color: #473a1c !important;
  }
  &:last-of-type {
    margin: 0;
  }
}

.InputModal {
  &.Large {
    min-width: 250px;
  }

  font-size: 14px;
  top: 0;
  left: 0;
  // z-index: 2;
  position: relative;
  height: 30px;
  width: 100%;
  font-weight: bold;
  padding: 5px 15px;
  border-radius: 15px;
  justify-content: space-between;
  display: flex;
  line-height: 18px;
  align-items: center;
  margin-right: 5px;
  color: #0a4148;
  background-color: #c4c4c4;

  &.Dark {
    .Active {
      color: #fff;
      background-color: #662d91;
      span {
        color: #fff;
      }
    }

    .Option {
      &:focus,
      &:hover {
        color: #fff;
        background-color: #93278f;
      }
    }
    .ActiveOption {
      color: #fff;
      background-color: darken(#93278f, 15%);
    }
  }
}
