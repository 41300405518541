iframe{
  pointer-events: none;
}

.DataTable {
  text-align: center;
}
.ButtonSearch {
  background-color: #e6e6e6;
  border-radius: 30px;
  width: 300px;
  height: 40px;
  padding: 0 15px;
  border: none !important;
}
.ButtonSeeMore {
  background-color: #fff;
  color: #fff;
  // border-radius: 30px;
  border: none !important;
}
.CardInfoUser {
  text-align: left;
  border-radius: 30px;
  padding: 15px;
  background-color: #e6e6e6;
  max-width: 420px;
  margin-bottom: 20px;
  .LineInfoUser {
    border-bottom: 2px solid #fff;
    padding: 2px 0;
  }
}
.ColorGreen {
  color: green;
  font-size: 12px;
  font-weight: bold;
}
.ColorRed {
  color: red;
  font-size: 12px;
  font-weight: bold;
}
.ButtonCancel {
  // text-align: center;
  color: #0A4148;
  background-color: #ffca55;
  padding: 5px;
  border-radius: 30px;
  font-weight: bold;
  text-transform: uppercase;
  width: 135px;
  font-size: 10px;
  left: 87%;
  border: none !important;
}

.ButtonEdit {
  // text-align: center;
  color: #0A4148;
  background-color: #ffca55;
  padding: 5px;
  border-radius: 30px;
  font-weight: bold;
  text-transform: uppercase;
  width: 135px;
  font-size: 10px;
  left: 87%;
  border: none !important;
  margin-top: 5px;
}