.DataTable{
    text-align: center;
}
.TitleAdjusted{
    margin: 40px 0 70px 0;
}
.ButtonSearch{
    background-color: #E6E6E6;
    border-radius: 30px;
    width: 300px;
    height: 40px;
    padding: 0 15px;
    border: none !important;
}
.ButtonSeeMore{
    background-color: #fff;
    color: #fff;
    // border-radius: 30px;
    border: none !important;
}
.CardInfoUser{
    text-align: left;
    border-radius: 30px;
    padding: 15px;
    background-color: #e6e6e6;
    max-width: 420px;
    margin-bottom: 20px;
    .LineInfoUser{
        border-bottom: 2px solid #fff;
        padding: 2px 0;
    }
}