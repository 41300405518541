.ButtonFunction {
  text-align: center;
  color: #0A4148;
  background-color: #ffca55;
  padding: 2px 15px;
  border-radius: 30px;
  font-weight: bold;
  text-transform: uppercase;
  border: none !important;
}
