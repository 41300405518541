.DataTable {
  text-align: center;
  margin-top: 30px;
  width: 100%;
}
.ButtonSearch {
  background-color: #e6e6e6;
  border-radius: 30px;
  width: 300px;
  height: 40px;
  padding: 0 15px;
  border: none !important;
}
.ButtonSeeMore {
  background-color: #fff;
  color: #fff;
  // border-radius: 30px;
  border: none !important;
}

.BtnAddPlan {
  text-align: center;
  color: #0a4148;
  background-color: #ffca55;
  padding: 10px 15px;
  border-radius: 30px;
  font-weight: bold;
  text-transform: uppercase;
  position: absolute;
  width: 250px;
  height: 40px;
  left: 80%;
  border: none !important;
}
