.DataTable{
    text-align: center;
}
.ButtonSearch{
    background-color: #E6E6E6;
    border-radius: 30px;
    width: 300px;
    height: 40px;
    padding: 0 15px;
    border: none !important;
}
.ButtonSeeMore{
    background-color: #fff;
    color: #fff;
    // border-radius: 30px;
    border: none !important;
}