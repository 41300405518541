.DataTable{
    text-align: center;
}
.ButtonSearch{
    background-color: #E6E6E6;
    border-radius: 30px;
    width: 300px;
    height: 40px;
    padding: 0 15px;
    border: none !important;
}
.ButtonSeeMore{
    position: relative;
    left: inherit;
    // border-radius: 30px;
    border: none !important;
    color: #0A4148;
    background-color: #ffca55;
    padding: 10px;
    border-radius: 30px;
    font-weight: bold;
    white-space: nowrap;
    text-transform: uppercase;
}

.EmailReffer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;
}