.DataTable {
  text-align: center;
  font-size: 10px;
  @media screen and (min-width: 1201px) and (max-width: 1440px) {
    font-size: 12px;
  }
  @media screen and (min-width: 1601px) {
    font-size: 14px;
  }
  th {
    padding: 8px 2px;
    background: var(--Neutral-Grey-1, #f9f8f9);
    border: 0.421px solid var(--Neutral-Grey-3, #eaeaea);
    flex: 1;
  }
  td {
    border: 0.421px solid var(--Neutral-Grey-3, #eaeaea);
    padding: 8px 2px;
  }
}
.ButtonsArea {
  display: flex;
  width: 400px;
  justify-content: space-between;
  padding: 16px;
  .Filter {
    width: 168px;
    height: 35px;
    background-color: #0a4148;
    border-radius: 5px;
    border: none;
    outline: none;
    color: #fff;
    font-size: 12.444px;
    font-weight: bold;
  }
  .CsvExport {
    width: 168px;
    height: 35px;
    border: 2px solid #0a4148;
    border-radius: 5px;
    outline: none;
    font-size: 12.444px;
    font-weight: bold;
    .CsvExportField {
      color: #0a4148;
    }
  }
}
.CardFilter {
  top: 105px;
  left: 18px;
  width: 236px;
  height: 228px;
  padding: 10px;
  background: #fff;
  box-shadow: 3px 1px 5px 2px rgba(0, 0, 0, 0.25);
  position: absolute;
  z-index: 9;
  .CheckboxArea {
    display: flex;
    justify-content: space-between;
    .Checkbox {
      display: flex;
      border-radius: 100%;
      input {
        cursor: pointer;
        margin-right: 5px;
        border-color: #0a4148;
        background-color: #0a4148;
        outline-color: #0a4148;
      }
      input:focus {
        cursor: pointer;
        margin-right: 5px;
        border-color: #0a4148;
        background-color: #0a4148;
        outline-color: #0a4148;
      }
      input:checked {
        cursor: pointer;
        margin-right: 5px;
        border-color: #0a4148;
        background-color: #0a4148;
        color: #0a4148;
      }

      label {
        cursor: pointer;
        margin: 0;
      }
    }
  }
  .DatepickerArea {
    .MonthFilter {
      .Filter {
        display: flex;
        justify-content: space-between;
        margin-top: 15px;
      }
    }
    .DaysFilter {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      .Filter {
        display: flex;
        justify-content: space-between;
        margin-top: 15px;
      }
    }
    .ApplyFilterDatepickerArea {
      position: relative;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      width: 100%;
      height: 70px;
      .ApplyFilterDatepicker {
        width: 80px;
        height: 24px;
        background-color: #0a4148;
        border-radius: 5px;
        border: none;
        outline: none;
        color: #fff;
        font-size: 10.545px;
      }
    }
  }
}
.ButtonSearch {
  background-color: #e6e6e6;
  border-radius: 30px;
  width: 300px;
  height: 40px;
  padding: 0 15px;
  border: none !important;
}
.ButtonSeeMore {
  background-color: #fff;
  color: #fff;
  // border-radius: 30px;
  border: none !important;
}
.NoContentArea {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 55vh;
  color: #7b7f86;

  text-align: center;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  img {
    width: 30vw;
  }
}
.ant-select-selector {
  min-width: 151px !important;
}
