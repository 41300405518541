.ButtonBacks {
    text-align: center;
  color: #0A4148;
  background-color: #ffca55;
  padding: 10px 15px;
  border-radius: 30px;
  font-weight: bold;
  text-transform: uppercase;
  position: absolute;
  width: 100px;
  height: 40px;
  left: 90%;
  top: 45px;
}
