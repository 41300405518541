.ButtonBacks {
  // text-align: center;
  color: #0A4148;
  background-color: #ffca55;
  padding: 5px;
  border-radius: 30px;
  font-weight: bold;
  text-transform: uppercase;
  position: absolute;
  width: 120px;
  font-size: 12px;
  left: 89%;
  border: none !important;
}
