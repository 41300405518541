.H1 {

  color: #0A4148;
  font-weight: bold;
  text-transform: none;
  font-size: 20px;
  text-transform: uppercase;
  box-sizing: border-box;
  position: relative;
  margin: 20px 0 20px 0;

  &.NoMT {
    margin-top: 50px;
  }
  svg {
    // max-height: 16px;
  }
}
.IconLeft {
  margin-right: 5px;
}
.IconRight {
  margin-left: 5px;
}
