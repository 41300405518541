.RewardTable {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    border-collapse: collapse;
    width: 100%;
}

.RewardsList{
display: flex;
justify-content: space-between;
}

.AddReward{
    padding: 50px;
}

.AddRewardForm{
   // background-color: red;
    input, textarea, select{
        border-radius: 4px;
        border-color: #BFBFBF;
        min-width: 250px;
        max-width: 400px;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-size: 16px;
    }
}

.EditReward{
    background-color: #137e8b !important;
    color: #ffffff !important;
    border-radius: 4px !important;
    font-weight: bold !important;
}

.AddButton{
    font-size: 15px !important;
    font-weight: 500 !important;
    background-color: #0a4148 !important;
    border-radius: 4px !important;
    margin: 50px 0 !important;
    border-color: #0a4148 !important;
    color: white !important;
}



.RewardTable td,
.RewardTable th {
    border: 1px solid #ddd;
    padding: 8px;
}

.RewardTable tr:nth-child(even) {
    background-color: #f2f2f2;
}

.RewardTable tr:hover {
    background-color: #ddd;
}

.RewardTable th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #0a4148;
    color: white;
}

.FormReward {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 0;
    margin: 0;
    label {
        margin-bottom: 0;
    }
    button {
        margin-top: 2%;
    }
}

.flexLeft {
    width: 50%;
}

.flexRight {
    width: 50%;
}

.AddRewardError {
    color: red;
    font-size: 20px;
    margin-left: -45%;
}

.addRewardArea {
    display: flex;
    justify-content: flex-start;
    button {
        width: 25%;
        margin-right: 25px;
    }
}

.addRewardSuccess {
    color: rgb(47, 255, 85);
}
