.ErrorMessage {
  background-color: #ffa155;
  color: #000;
  margin-top: 10px;
}

.spaceBt {
  margin-bottom: 30px;
}

.WrapModalhide {
  display: none;
}
.WrapModal {
  opacity: 1;
  display: flex !important;
  //pointer-events: none;

  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 20000;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  .ContentModalAdd {
    display: block;
    cursor: default;
    width: 100%;
    max-width: 600px;
    .TopBar {
      background: #0a4148;
      background-size: cover;
      height: 75px;
      z-index: 10;
      width: 540px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      border-radius: 15px 15px 0 0;
      position: relative;
      max-width: 600px;
      .CloseButtonX {
        background-color: #fff;
        color: #0a4148;
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-weight: 900;
        border-radius: 20px;
        float: right;
        font-size: 1.4em;
        text-align: center;
        margin: 25px 20px 10px 10px;
        cursor: pointer;
      }
    }
    .Information {
      background-color: #fff;
      padding: 10px 10px 10px 10px;
      position: relative;
      margin-top: -40px;
      z-index: 5;
      border-radius: 15px;
      overflow-x: hidden;
      @media (min-width: 768px) {
        padding: 40px;
      }
      img {
        width: 120px;
        display: inline-block;
      }
      .Title {
        width: 100%;
        text-align: center;
        align-items: center;
        margin: 20px 0;
        font-size: 16px;
        font-weight: bold;
        text-transform: uppercase;
      }
      .ModalUserEmail {
        padding: 5px;
        color: #0a4148;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        align-items: center;
        background-color: #c4c4c4;
        border-radius: 30px;
        width: 340px;
        min-height: 35px;
        margin: 0 auto 10px;
      }
      .ModalLabel {
        font-weight: bold;
      }
      .ItsDoneWrap {
        border: solid 1px #cccccc;
        border-radius: 4px;
        display: table;
        float: left;
        margin: 0 10px 10px 0;
        padding: 4px 10px;
        span {
          color: #662d91;
          margin-right: 4px;
        }
      }
      .Image {
        width: 100%;
        max-width: 200px;
        margin: 0 auto;
        img {
          width: 100%;
        }
      }
      .WrapSteps {
        display: block;
        width: 100%;
        border-top: solid 1px #cccccc;
        margin: 20px 0 30px 0;
        padding: 20px;
        .Steps {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          /*max-width: 400px;*/
          margin: 0 auto;
          .StepsBar {
            display: flex;
            height: 100%;
            float: left;
            max-width: 90%;
            justify-content: flex-end;
            svg {
              width: 100%;
            }

            .ColorMe {
              fill: #ffca55;
            }
            .GrayMe {
              fill: #d7d7d7;
            }
          }
          .stepsNum1 {
            display: none;
          }
          .stepsNum2 svg {
            max-width: 60px;
          }
          .stepsNum3 svg {
            max-width: 120px;
          }
          .stepsNum4 svg {
            max-width: 180px;
          }
          .stepsNum5 svg {
            max-width: 240px;
          }
          .stepsNum6 svg {
            max-width: 300px;
          }
          .stepsNum7 svg {
            max-width: 360px;
          }
          .stepsNum8 svg {
            max-width: 420px;
          }
          .stepsNum9 svg {
            max-width: 480px;
          }
          .stepsNum10 svg {
            max-width: 540px;
          }
          .Star {
            width: 10%;
            float: left;
            margin-left: -8px;
            //max-width: 20%;
            svg {
              max-width: 80px;
              width: 100%;
            }
          }
        }
      }
      .OptionsYesOrNo {
        display: flex;
        justify-content: space-around;
        .CloseButtonTextAddModal {
          background-color: #ffca55;
          color: #0a4148;
          display: table;
          text-transform: uppercase;
          font-weight: bold;
          font-size: 1em;
          // line-height: 40px;
          padding: 5px 20px;
          border-radius: 20px;
          margin: 25px 0 0 0;
          text-align: center;
          pointer-events: fill !important;
        }
      }
    }
  }
}

.sucessAdd {
  background-color: #0a4148;
  color: #000;
  margin-top: 10px;
}