.TopNav {
  box-sizing: border-box;
  position: fixed;
  width: 100%;
  top: 0px;
  left: 0;
  z-index: 9999;
  min-height: 80px;
  .BtnFlag {
    margin-right: 0;
  }
  .Flag {
    width: 40px;
    min-width: 40px;
    height: 0;
    min-height: 0;
    margin-right: 0;
    padding-bottom: 100%;
    position: relative;
    svg {
      height: auto;
    }
  }
  .IconChangeArea {
    left: 0;
    height: 0;
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .IconChange {
    position: absolute;
    margin: 0 auto;
    transform: translate(-50%, -50%) scale(0.8);
    left: 50%;
    top: 50%;
  }
  .DesktopNav {
    padding: 0 15px;
    background-color: #0a4148;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 9999;
    position: relative;
    box-shadow: 0px 0px 10px #ccc;
    &:focus {
      box-shadow: 0px 0px 5px 0px rgba(#333333, 0.3);
    }
    // img {
    //   background-color: white;
    //   border-radius: 15px;
    //   padding: auto;
    // }
  }

  .LeftMenu {
    width: 100%;
    //display: none;
  }

  .RightMenu {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
  .LeftMenu,
  .RightMenu {
    flex: 1 1 auto;
    align-items: center;
    box-sizing: border-box;
    flex-wrap: nowrap;
  }
  .Logo {
    // text-align: left;
    display: inline-block;
    box-sizing: border-box;
    height: 60px;
    width: 210px;
    image-rendering: -webkit-optimize-contrast;
    background-color: white;
    border-radius: 15px;
    margin: 10px;
    figure {
      height: 80px;
      transition: 200ms;
      padding: 15px 10px;
      display: inline-block;
      margin-top: -5px;
      &:hover {
        padding: 14px 10px;
      }
    }
    img {
      height: 100%;
      width: auto;
    }
  }

  .BtnToggleMenu {
    min-width: 40px;
    min-height: 40px;
    width: 40px;
    height: 40px;
    position: relative;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 100%;
    border: 2px solid #1ab9de;
    display: flex;
    margin-left: 10px;
    transition: 200ms;
    span {
      height: 2px;
      width: 20px;
      background-color: #333333;
      margin-bottom: 3px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .TopNavAvatar {
    min-height: 50px !important;
    height: 50px !important;
    min-width: 50px !important;
    width: 50px !important;
  }

  .NavList {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
  }

  .MobileNav {
    transition: 200ms;
    overflow: hidden;
    display: none;
    // transform: scaleY(0);
    // transform-origin: top;
  }

  $borderSize: 7px;
  .BtnAvatar {
    display: none;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 20px;
    position: relative;
    text-align: left;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    min-width: 300px;
    margin-left: 10px;
    width: 260px;
    font-size: 11px;
  }

  .UserName {
    font-size: 1.2em;
    width: 100%;
    display: block;
    color: #0A4148;
    font-weight: 900;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
  }

  .SubMenuUser {
    position: absolute;
    right: 0;
    bottom: -10px;
    background-color: #fdfdfd;
    padding: 15px;
    overflow: hidden;
    transform: translateY(100%) scaleY(0);
    transition: 200ms;
    transform-origin: top;
    &:focus {
      box-shadow: 0 0 7px 5px rgba(0, 0, 0, 0.05);
    }
    // .SubLink {
    //   min-width: 150px;
    //   @include getLinkAHF();
    //   white-space: nowrap;
    //   display: block;
    //   width: 100%;
    //   text-align: left;
    //   margin-bottom: 15px;
    //   &.LastLink {
    //     margin-bottom: 0;
    //   }
    // }
  }
  .PreventReOpen {
    position: relative;
    &::after {
      content: "";
      width: 100%;
      // height: 100%;
      position: absolute;
      z-index: 9999;
      top: 0;
      left: 0;
    }
  }
  @media (min-width: 768px) {
    .SingleUser {
      display: none;
    }
    .UserMenu {
      position: relative;
    }
    .PreventReOpen {
      .BtnAvatar::after {
        transform: scaleY(-1);
      }
      .SubMenuUser {
        transform: translateY(100%) scaleY(1);
      }
    }
    .BtnAvatar {
      display: flex;
      position: relative;
      // @include getAHF();
      // &::after {
      //   position: absolute;
      //   right: 5px;
      //   content: "";
      //   border-right: $borderSize solid transparent;
      //   border-left: $borderSize solid transparent;
      //   border-top: $borderSize solid $gray;
      //   transition: 200ms;
      // }
    }
    .UserName {
      &::after {
        content: "";
        right: 0;
        position: absolute;
        width: 10px;
        height: 100%;
      }
    }
  }

  .BtnArrowDown {
    span {
      margin: 0;
      &:nth-child(1) {
        transform: translate(0px, 2px) scale(1.2) rotate(45deg);
      }
      &:nth-child(2) {
        transform: translate(0px, 0px) scale(1.2) rotate(135deg);
      }
      &:nth-child(3) {
        opacity: 0;
      }
    }
  }
  @media (min-width: 768px) {
    .BtnToggleMenu {
      display: none;
    }
  }
  @media (min-width: 992px) {
    .LeftMenu {
      display: block;
    }
  }

  .MobileNavAnimation {
    height: 100%;
    transform: translateY(-100%);
  }

  .Name {
    min-width: 100px;
    width: 100%;
  }

  .Overlay {
    position: fixed;
    background-color: transparent;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 9999;
    &::after {
      content: "";
      position: absolute;
      background-color: rgba(#333333, 0.7);
      width: 100%;
      height: 100%;
      margin-top: 80px;
    }
  }
  .BtnChangeUser {
    // @include getButton($secondaryColor, $primaryColor, $primaryColor);
    padding: 5px 10px;
    font-size: 15px;
  }
}

.NavRight {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100vh;
  width: 100%;
  max-width: 330px;
  margin-right: 0;
  right: 0;
  top: 0;
  background-color: #fdfdfd;
  z-index: 9999;
  transform: translateX(500px);
  transition: 300ms;
  &.OpenRNav {
    transform: translate(0);
    overflow-y: auto;
  }
  .RNavUserHead,
  .RNavMain,
  .RNavUserNav {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .RNavUserHead {
    background-color: #ffffff;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 15px;
    .BtnAvatar {
      display: flex;
      position: relative;
    }
  }

  .RNavMain {
    background-color: #fdfdfd;
    text-transform: uppercase;
    padding: 0px;
    .SubLink {
      padding: 15px 16px;
      border-bottom: 1px solid #ffffff;
      font-size: 13px;
      &.LastLink {
        margin-bottom: 0;
      }
    }
  }

  .RNavUserNav {
    background-color: #ffffff;
    flex: 1 1 auto;
    width: 100%;
    padding-top: 15px;
  }

  .SignOut,
  .SubLink {
    // @include getLinkAHF($grayDark, $secondaryColor);
    color: #4d4d4d;
    white-space: nowrap;
    display: block;
    text-align: left;
    padding: 15px;
    &.LastLink {
      margin-bottom: 0;
    }
  }
  .SubLink {
    width: 100%;
    min-width: 150px;
    text-transform: uppercase;
    font-size: 13px;
    padding: 15px 0;
  }

  .SignOut {
    min-width: unset;
    border: 1px solid;
    border-radius: 50px;
    width: min-content;
    padding: 10px 25px;
    margin-top: 15px;
  }
}
.IconNav {
  color: #0A4148;
  font-size: 25px;
}
