.Active {
  border-top: 0px solid lighten(#faaa3c, 50%);
  border-left: 0px solid lighten(#faaa3c, 50%);
  border-right: 0px solid lighten(#faaa3c, 50%);
  font-size: 10px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
}
.DropdownList {
  border-bottom: 0px solid lighten(#faaa3c, 50%);
  border-left: 0px solid lighten(#faaa3c, 50%);
  border-right: 0px solid lighten(#faaa3c, 50%);
}
.Dropdown {
  font-size: 14px;
  display: inline-block;
  position: relative;
  min-width: 240px;
  height: 30px;
  z-index: inherit;
  cursor: pointer;
  &.Large {
    min-width: 250px;
  }
  .Active {
    font-size: 14px;
    top: 0;
    left: 0;
    // z-index: 2;
    position: relative;
    height: 30px;
    width: 100%;
    font-weight: bold;
    padding: 5px 15px;
    border-radius: 15px;
    justify-content: space-between;
    display: flex;
    line-height: 18px;
    align-items: center;
    margin-right: 5px;
    color: #0a4148;
    background-color: #c4c4c4;
  }
  &.Dark {
    .Active {
      color: #fff;
      background-color: #662d91;
      span {
        color: #fff;
      }
    }

    .Option {
      &:focus,
      &:hover {
        color: #fff;
        background-color: #93278f;
      }
    }
    .ActiveOption {
      color: #fff;
      background-color: darken(#93278f, 15%);
    }
  }
}

.DropdownList {
  position: absolute;
  width: 100%;
  z-index: 10;
  top: 0;
  left: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow: hidden;
  transform-origin: top;
  height: auto;
  //margin-top: 30px;

  transform: scaleY(0);
}
.Select {
  // padding-top: 20px;
  z-index: inherit;
}

.Option {
  display: none;
  padding: 10px 15px;
  width: 100%;
  text-align: left;
  background-color: #fff;
  &:last-of-type {
    margin: 0;
  }
  &:focus,
  &:hover {
    padding: 10px 15px;
    background-color: #ffca55;
  }
}
.ActiveOption {
  background-color: lighten(#ffca55, 15%);
}
.DropdownList,
.Icon {
  transition: 300ms;
}

.Open {
  .Active {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    position: relative;
  }
  .Option {
    display: block;
  }
  .DropdownList {
    transform: scaleY(1);

    box-shadow: -5px 5px 9px -4px #63636377;
  }
  .Icon {
    transform: rotate(180deg);
  }
}
.Huge {
  transform-origin: top left;
}
