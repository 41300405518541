.Layout {
  background-color: #FFF;
  // margin-top: 50px;
  display: flex;
  padding-bottom: 0px;
  min-height: calc(100vh - 60px);
  @media (min-width: 992px) {
    padding-bottom: 0px;
  }
  .Aside + .Main {
    margin-left: 0;
    margin-right: 150;
    @media (min-width: 992px) {
      margin-left: 250px;
    }
  }
  .Footer + .Main {
    margin-top: 0;
    margin-bottom: 0;
    @media (min-width: 992px) {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  .Main {
    padding-right: 15px;
    @media (min-width: 992px) {
      padding-left: 35px;
      padding-right: 35px;
    }
    @media (min-width: 992px) {
      padding-left: 10px;
      padding-right: 65px;
    }
  }
  .padd_solutions {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  min-height: calc(100vh);
  // footer
  // .InnerArea {
  //     overflow: hidden;
  //     height: calc(100vh - 3.5rem);
  //   }
  // .footerStyle {
  //   position: relative;
  //   width: 100%;
  //   text-align: center;
  //   min-height: 20px;
  //   right: 0px;
  //   justify-content: center;
  //   align-items: center;
  //   bottom: 0px;
  //   padding: 20px 15px 0px;
  //   letter-spacing: 1px;
  //   z-index: $zindexMaitTitle;
  //   line-height: 1em;
  //   font-size: 0.7em;
  // }
  // .footerSpace {
  //   padding: 5px 15px;
  // }
}
